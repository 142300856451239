import * as React from "react";
import { useParams, Link as RouterLink } from "react-router-dom";
import { Divider, Chip, Link, Grid, Typography, Button, Breadcrumbs, makeStyles, Avatar, Badge, Paper, Icon } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import SentimentSatisfiedIcon from "@material-ui/icons/SentimentSatisfied";
import WorkOutlineIcon from "@material-ui/icons/WorkOutline";
import ScheduleIcon from "@material-ui/icons/Schedule";
import FavoriteIcon from "@material-ui/icons/Favorite";
import InventoryIcon from "@mui/icons-material/Inventory";

import { Category, Difficulty, ItemHeader, ItemDetails, ItemOutcomes } from "./components/items";
import { ConstrainedPageContent, Page, StretchedContent } from "./components/layout";
import { TagList } from "./components/tags";
import { useUserContext } from "./components/auth/UserContextProvider";
import { useQuery } from "@apollo/client";
import { GET_BEACON_ITEM } from "../api/graphQL/queries";
import { BeaconItem, BeaconItemCategory } from "../api/graphQL/queryModels";
import { red } from "@material-ui/core/colors";
import { useNotifications } from "./components/notifications/NotificationContextProvider";
import DocumentTitle from "./components/DocumentTitle";
import { Author } from "./components/people";
import ArchiveItem from "./components/items/ArchiveItem";
import ArchivedDisplay from "./components/items/ArchivedDisplay";
import { useAppContext } from "../AppContextProvider";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "flex",
        alignItems: "center"
    },
    skilledPeople: {
        "& > *": {
            marginRight: theme.spacing(1),
            marginBottom: theme.spacing(1)
        }
    },
    skilledPerson: {
        width: 48,
        height: 48
    },
    heart: {
        color: red[500],
        fontSize: 18
    },
    archiveIcon: {
        color: theme.palette.text.accent
    },
    button: {
        marginLeft: "10px",
        height: "36.5px",
        marginBottom: "15px"
    }
}));

const ItemDetailPage = () => {
    const classes = useStyles();
    const { id } = useParams<{ id: string }>();
    const { setNotification } = useNotifications();
    const [isOpen, setIsOpen] = React.useState(false);
    const { context } = useAppContext();

    const { user: currentUser, isContentAdmin } = useUserContext();

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const { data, loading, error } = useQuery<{ beaconItem: BeaconItem }>(GET_BEACON_ITEM, {
        variables: {
            id
        }
    });

    const item = data?.beaconItem;
    const canEdit = !!item && ((!!item.author && currentUser?.id === item?.author.id) || isContentAdmin);
    const isFetching = !item || loading;

    React.useEffect(() => {
        if (error?.message) {
            setNotification(error.message, "error");
        }
    }, [error, setNotification]);

    return (
        <>
            {!!item && <DocumentTitle pageTitle={item.name} />}
            <Page noMarginTop className="beacon-detail-page">
                {isFetching || !item ? (
                    <>
                        <ConstrainedPageContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h3">
                                        <Skeleton variant="text" width="45%" />
                                    </Typography>
                                    <Typography variant="body1">
                                        <Skeleton variant="text" />
                                        <Skeleton variant="text" />
                                        <Skeleton variant="text" />
                                        <Skeleton variant="text" />
                                        <Skeleton variant="text" width="30%" />
                                    </Typography>
                                </Grid>
                                <Grid item />
                                <Grid item xs={12} className="avatar-placeholders">
                                    <Skeleton variant="circle" width={48} height={48} />
                                    <Skeleton variant="circle" width={48} height={48} />
                                    <Skeleton variant="circle" width={48} height={48} />
                                    <Skeleton variant="circle" width={48} height={48} />
                                    <Skeleton variant="circle" width={48} height={48} />
                                    <Skeleton variant="circle" width={48} height={48} />
                                </Grid>
                            </Grid>
                        </ConstrainedPageContent>
                    </>
                ) : (
                    <>
                        <StretchedContent>
                            <ItemHeader item={item}>
                                <Paper className="beacon-detail-background">
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} container justifyContent="space-between">
                                            <Grid item>
                                                <Breadcrumbs aria-label="breadcrumb">
                                                    <Link color="textPrimary" component={RouterLink} to="/?view=items">
                                                        Items
                                                    </Link>
                                                    <Typography color="textPrimary" style={{ fontWeight: 600 }}>
                                                        {item.name}
                                                    </Typography>
                                                </Breadcrumbs>
                                            </Grid>
                                            <Grid item>
                                                {item.isArchived ? <ArchivedDisplay /> : null}
                                                {canEdit && !item?.isArchived ? (
                                                    <Button
                                                        component={RouterLink}
                                                        to={`/items/${item.id}/edit`}
                                                        aria-label="Edit"
                                                        variant="outlined"
                                                        startIcon={<Icon>edit</Icon>}
                                                        className={classes.button}
                                                    >
                                                        Edit
                                                    </Button>
                                                ) : null}
                                                {canEdit ? (
                                                    <Button
                                                        onClick={() => setIsOpen(true)}
                                                        startIcon={<InventoryIcon className={classes.archiveIcon} />}
                                                        variant="outlined"
                                                        className={classes.button}
                                                    >
                                                        {!item.isArchived ? "Archive Item" : "Unarchive Item"}
                                                    </Button>
                                                ) : null}
                                                {isOpen && <ArchiveItem isOpen={isOpen} setIsOpen={setIsOpen} />}
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <div className={classes.title}>
                                                <Typography className="beacon-name" variant="h3" component="h3">
                                                    {item.name}
                                                </Typography>
                                            </div>
                                            <Category category={item.category || BeaconItemCategory.None} />
                                        </Grid>
                                        <Grid item xs={12} sm={6} container justifyContent="flex-end">
                                            <Grid item xs={12}>
                                                {item.category === BeaconItemCategory.Technique && (
                                                    <div className="meta-details">
                                                        <Chip
                                                            variant="outlined"
                                                            label={item.people ? item.people : "—"}
                                                            icon={<PeopleOutlineIcon />}
                                                        />
                                                        <Chip
                                                            variant="outlined"
                                                            label={item.preparation ? item.preparation : "—"}
                                                            icon={<WorkOutlineIcon />}
                                                        />
                                                        <Chip
                                                            variant="outlined"
                                                            label={item.duration ? item.duration : "—"}
                                                            icon={<ScheduleIcon />}
                                                        />
                                                        <Chip
                                                            variant="outlined"
                                                            label={item.difficulty ? <Difficulty difficulty={item.difficulty} /> : "—"}
                                                            icon={<SentimentSatisfiedIcon />}
                                                        />
                                                    </div>
                                                )}
                                            </Grid>
                                            <Grid item xs={12}>
                                                <div className="beacon-info-tags">
                                                    <TagList tags={item.tags || []} size="medium" />
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </ItemHeader>
                        </StretchedContent>
                        <Divider variant="fullWidth" />
                        <br />
                        <StretchedContent>
                            <Paper className="beacon-detail-background">
                                <ItemDetails beacon={item} />
                                {item.author && (
                                    <div className="beacon-detail-page-author">
                                        <Author author={item.author} createDate={item.dateCreated} />
                                    </div>
                                )}
                                {!!item.skilledPeople?.length && (
                                    <ConstrainedPageContent className="beacon-detail-page-linked-people">
                                        <Typography variant="h6" component="h6">
                                            Advocates
                                        </Typography>
                                        <div className={classes.skilledPeople}>
                                            {item.skilledPeople.map((skilledPerson) => {
                                                const initials =
                                                    (skilledPerson.person?.givenName || "?").substr(0, 1) +
                                                    (skilledPerson.person?.surname || "?").substr(0, 1);
                                                return (
                                                    <Link
                                                        key="Link 1"
                                                        href={`${context?.settings?.relatedAppsUri?.peopleApp}/people/${skilledPerson.person?.id}`}
                                                        target="_blank"
                                                    >
                                                        <Badge
                                                            key={skilledPerson.id}
                                                            overlap="circle"
                                                            anchorOrigin={{
                                                                vertical: "bottom",
                                                                horizontal: "right"
                                                            }}
                                                            badgeContent={
                                                                skilledPerson.isLoved ? (
                                                                    <FavoriteIcon className={classes.heart} />
                                                                ) : undefined
                                                            }
                                                        >
                                                            <Avatar
                                                                className={classes.skilledPerson}
                                                                src={
                                                                    skilledPerson.person?.photos.length
                                                                        ? skilledPerson.person?.photos[0].uri
                                                                        : "/img/no_photo.png"
                                                                }
                                                            >
                                                                {initials}
                                                            </Avatar>
                                                        </Badge>
                                                    </Link>
                                                );
                                            })}
                                        </div>
                                    </ConstrainedPageContent>
                                )}
                                {!!item.stories?.length && (
                                    <>
                                        <ConstrainedPageContent className="beacon-detail-page-stories-header">
                                            <Typography variant="h6" component="h6" gutterBottom>
                                                Outcomes
                                            </Typography>
                                        </ConstrainedPageContent>
                                        <ConstrainedPageContent className="beacon-detail-page-stories-items">
                                            <ItemOutcomes linkedStories={item.stories} item={item} />
                                        </ConstrainedPageContent>
                                    </>
                                )}
                            </Paper>
                        </StretchedContent>
                    </>
                )}
            </Page>
        </>
    );
};

export default ItemDetailPage;
