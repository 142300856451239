import * as React from "react";
import { GetLanternAppTheme, LoadingView } from "@lantern/react-components";
import DocumentTitle from "./components/DocumentTitle";
import { CssBaseline, ThemeProvider, useMediaQuery } from "@material-ui/core";

const AuthCompletePage = () => {
    const theme = useMediaQuery("(prefers-color-scheme: dark)") ? "dark" : "light";
    return (
        <>
            <ThemeProvider theme={GetLanternAppTheme({ authenticated: false, type: theme })}>
                <CssBaseline />
                <DocumentTitle pageTitle="Authenticating" />
                <LoadingView text="Completing authorization" />
            </ThemeProvider>
        </>
    );
};

export default AuthCompletePage;
