import * as React from "react";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useAuth } from "./AuthProvider";
import { GetLanternAppTheme, LoadingView } from "@lantern/react-components";
import AuthenticatedLayout from "./AuthenticatedLayout";

const AuthenticatedView = (props: { children: React.ReactNode }) => {
    const { isLoading, isAuthenticated, login } = useAuth();
    const theme = useMediaQuery("(prefers-color-scheme: dark)") ? "dark" : "light";

    React.useEffect(() => {
        if (!isLoading && !isAuthenticated && !!login) {
            window.sessionStorage.setItem("redirectUri", window.location.pathname);
            login();
        }
    }, [isLoading, isAuthenticated, login]);

    return isAuthenticated ? (
        <AuthenticatedLayout>{props.children}</AuthenticatedLayout>
    ) : (
        <ThemeProvider theme={GetLanternAppTheme({ authenticated: false, type: theme })}>
            <CssBaseline />
            <LoadingView text="Authenticating..." />
        </ThemeProvider>
    );
};

export default AuthenticatedView;
