import { GetLanternAppTheme } from "@lantern/react-components";
import { CssBaseline, ThemeProvider, useMediaQuery } from "@material-ui/core";
import { useAppContext } from "../../../AppContextProvider";
import { NotificationContextProvider, Notifications } from "../notifications";
import TelemetryContextProvider from "../../../telemetry/TelemetryContextProvider";
import AuthenticatedApolloProvider from "../../../api/graphQL/AuthenticatedApolloProvider";
import UserContextProvider from "./UserContextProvider";
import AppHeader from "../../../AppHeader";
import "@fontsource/montserrat";
import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/500.css";
import "@fontsource/montserrat/600.css";

const AuthenticatedLayout = (props: { children: React.ReactNode }) => {
    const theme = useMediaQuery("(prefers-color-scheme: dark)") ? "dark" : "light";
    const { context } = useAppContext();

    return (
        <ThemeProvider theme={GetLanternAppTheme({ authenticated: true, type: theme })}>
            <CssBaseline />
            <NotificationContextProvider>
                <AuthenticatedApolloProvider uri={`${context?.settings.apiUrl}graphql`}>
                    <UserContextProvider>
                        <TelemetryContextProvider>
                            <div>
                                <AppHeader />
                                <Notifications />
                                <div className="main-content">{props.children}</div>
                            </div>
                        </TelemetryContextProvider>
                    </UserContextProvider>
                </AuthenticatedApolloProvider>
            </NotificationContextProvider>
        </ThemeProvider>
    );
};

export default AuthenticatedLayout;
